<template>
  <v-btn
    text
    color="primary"
    href="https://riskscoreadvisors.com"
    target="_blank"
    >Financial Advisors</v-btn
  >
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
